// import node module libraries
import { Fragment, useEffect, useState } from "react";
import { Col, Row, Card, Tab, Breadcrumb, Nav, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import io from "socket.io-client";

// import sub components
import ClientPresenceTable from "./clientPresenceTable";
import { useDispatch } from "react-redux";
import { ABSENT_ACTION } from "actions/constants";

const socket = io("https://api-sprintup.intellect.tn");
// const socket = io("http://localhost:5034");
const ClientPresencePage = () => {
  const dispatch = useDispatch();
  const [clientId, setClientId] = useState(null);

  useEffect(() => {
    socket.on("connect", () => {
      console.log(" Socket connected to server");
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from server");
    });

    // Listen for "present" event from the server
    socket.on("presence", (data) => {
      console.log("Received present event from server:", data);
      dispatch({ type: ABSENT_ACTION, payload: data });
      toast.info("Welcome back " + data?.clientId?.name + " 👋🤗👋");
    });

    // Clean up on unmount
    return () => {
      socket.disconnect();
    };
  }, []);
  return (
    <Fragment>
      <Tab.Container defaultActiveKey="list">
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="border-bottom pb-4 mb-4 d-flex align-items-center justify-content-between">
              <div className="mb-3 mb-md-0">
                <h1 className="mb-1 h2 fw-bold">Presence Management</h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                  <Breadcrumb.Item href="#">
                    Presenses management
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              {/* <div>
								<GridListViewButton keyList="list" keyGrid="grid" />
							</div> */}
            </div>
            {/* <Col className="d-flex justify-content-end">
							<div>
							<AutocompliteClient
							selectedOption = {selectedOption}
							setSelectedOption = {setSelectedOption}
                            class_id = {class_id}
                        
							/>
                            	<div className="mt-3">
							<Button type="submit"
								variant="primary"
								id="add-new-event-btn"
							    onClick={(e) => handleAddClient(e)}
							>
								Add Client To this Class
							</Button>
						</div>
						

							</div>
						</Col> */}
            {/* <Col className="d-flex justify-content-end">
							<div>
								<AddNewClient
									showOffcanvas={showEventOffcanvas}
									setShowOffcanvas={setShowEventOffcanvas}
									setClientId={setClientId}
									clientId={clientId}
								/>

							</div>
						</Col> */}
          </Col>
        </Row>
        <Tab.Content>
          <Tab.Pane eventKey="list" className="pb-4">
            {/* students in list view */}
            <ClientPresenceTable setClientId={setClientId} />
            {/* <StudentsListItems /> */}
            {/* end of students in list view */}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      <ToastContainer />
    </Fragment>
  );
};

export default ClientPresencePage;
