import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllUsersAction } from "actions/users";
import { useParams } from "react-router-dom";
import { editBoardAction, getMembersAction } from "actions/boardKanban";
import { Modal, Button, Spinner, Tabs, Tab } from "react-bootstrap";
import {
  addMetaCardToMemberAction,
  getCardsInBoardAction,
} from "actions/boardCard";
import { fetchStudentsAction } from "actions/authClient";
import MetaModal from "./MetaModal";

const AutocompleteUsers = ({
  selectedUser,
  setSelectedUser,
  setShowAddMember,
}) => {
  const { id: idOfBoard } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [metaCards, setMetaCards] = useState([]);
  const [selectedMetaCards, setSelectedMetaCards] = useState([]);
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.users);
  const { students } = useSelector((state) => state.clients);
  const { loading } = useSelector((state) => state.auth);
  const [type, setType] = useState("staff");
  useEffect(() => {
    dispatch(fetchAllUsersAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchStudentsAction());
  }, [dispatch]);

  const { members } = useSelector((state) => state.boards);
  useEffect(() => {
    dispatch(getMembersAction(idOfBoard));
  }, [dispatch, idOfBoard]);

  
  const usersNotInMembers = users?.filter(
    (user) => !members?.some((member) => member?.name === user?.name)
  );

  const studentNotInMembers = students?.filter(
    (student) => !members?.some((member) => member?.name === student?.name)
  );
  const { cardInBoard } = useSelector((state) => state.boards);
  useEffect(() => {
    dispatch(getCardsInBoardAction(idOfBoard));
  }, [dispatch, idOfBoard]);
  useEffect(() => {
    const filteredMetaCards = cardInBoard.filter(
      (card) => card.type === "meta" && card.archived === false
    );
    setMetaCards(filteredMetaCards);
  }, [cardInBoard]);
  const handleCheckboxChange = (e, cardId) => {
    const checked = e.target.checked;
    if (checked) {
      setSelectedMetaCards((prevSelected) => [...prevSelected, cardId]);
    } else {
      setSelectedMetaCards((prevSelected) =>
        prevSelected.filter((id) => id !== cardId)
      );
    }
  };
  const handleClose2 = () => {};
  const handleClose = () => {
    setShowAddMember(false);
    setSelectedUser([]);
  };

  const handleConfirm = () => {
    dispatch(
      addMetaCardToMemberAction(
        {
          userId: selectedUser[0]._id,
          cardIds: selectedMetaCards,
          memberType: selectedUser.role ? "User" : "Client",
        },
        idOfBoard,
        handleClose
      )
    );
    setShowModal(false);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedUser([]);
    setShowAddMember(false);
  };

  const handleSelection = (selected) => {
    if (selected.length > 0) {
      setSelectedUser(selected);
    }
  };
  const handleAddMember = () => {
    if (selectedUser.length > 0) {
      dispatch(
        editBoardAction(
          {
            $push: {
              members: {
                userId: selectedUser[0]._id,
                memberType: type == "staff" ? "User" : "Client",
              },
            },
          },
          idOfBoard,
          handleClose2
        )
      );
      if (metaCards.length > 0) {
        setShowModal(true);
      } else {
        setShowAddMember(false);
        setSelectedUser([]);
      }
    }
  };

  const options = [...usersNotInMembers];
  return (
    <div className="mb-3">
      <Tabs
        defaultActiveKey="staff"
        id="uncontrolled-tab"
        className="mb-3"
        variant="pills"
        onSelect={(e) => setType(e)}
      >
        <Tab eventKey="staff" title="Staff">
          <Typeahead
            id="autocomplete-example"
            labelKey="name"
            options={options}
            placeholder="Type to search staff..."
            selected={selectedUser}
            onChange={handleSelection}
          />
        </Tab>
        <Tab eventKey="student" title="Student">
          <Typeahead
            id="autocomplete-example"
            labelKey="name"
            options={studentNotInMembers}
            placeholder="Type to search student..."
            selected={selectedUser}
            onChange={handleSelection}
          />
        </Tab>
      </Tabs>

      <div className="mt-3">
        <button
          type="button"
          className="btn btn-primary me-2"
          onClick={() => handleAddMember()}
        >
          {loading ? (
            <Spinner style={{ height: "20px", width: "20px" }} />
          ) : (
            "Save"
          )}
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => handleClose()}
        >
          Close
        </button>
      </div>
      <MetaModal
        handleCloseModal={handleCloseModal}
        showModal={showModal}
        handleCheckboxChange={handleCheckboxChange}
        handleConfirm={handleConfirm}
        metaCards={metaCards}
      />
    </div>
  );
};

export default AutocompleteUsers;
