import {
  getJsonAction,
  //   getJsonAction,
  getWordAction,
  splitStoryAction,
  updateJsonAction,
} from "actions/words";
import React, { useEffect, useState } from "react";
import { Col, Row, Modal, Button, ButtonGroup, Spinner } from "react-bootstrap";
import { Edit, Eye } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const WordsAnalysis = ({ title }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.auth);

  const [selectedWord, setSelectedWord] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showLevels, setShowLevels] = useState(false);
  const { words, word } = useSelector((state) => state?.words);
  console.log(words);

  useEffect(() => {
    dispatch(getJsonAction(title));
  }, []);

  const handleWordClick = (word) => {
    setSelectedWord(word);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedWord({});
    setShowModal(false);

    setShowLevels(false);
  };

  const handleCheckLevel = () => {
    setShowLevels(true);
    dispatch(getWordAction(selectedWord.word));
  };

  const approveDataToJsonFile = () => {
    let story = words.map((word) => {
      if (word.index == selectedWord.index) {
        return { ...selectedWord, checked: 1, approved: 1 };
      }
      return word;
    });

    dispatch(updateJsonAction({ title, words: story }));
    handleCloseModal();
  };
  const checkDataToJsonFile = () => {
    let story = words.map((word) => {
      if (word.index == selectedWord.index) {
        return { ...selectedWord, checked: 1, approved: 0 };
      }
      return word;
    });

    dispatch(updateJsonAction({ title, words: story }));
    handleCloseModal();
  };

  return (
    <div>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div>
            <h3>Word List:</h3>
            <div className="d-flex flex-wrap">
              <ButtonGroup
                className="mb-2"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {words?.map((word, index) => {
                    if (word.pos === "SPACE") {
                      return <div key={index} style={{ width: "100%" }} />;
                    } else {
                      return (
                        <Button
                          key={index}
                          onClick={() => handleWordClick(word)}
                          className={"mb-2 "}
                          style={{
                            backgroundColor:
                              !word.approved && !word.checked
                                ? "white"
                                : word.checked && word.approved
                                ? "#38a169"
                                : "#f59e0b",
                            marginRight: "10px",
                            marginBottom: "10px", // Add margin to bottom to separate rows
                            height: "15px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "black",
                            border: "0 solid white",
                            width: "auto",
                          }}
                        >
                          {word.word}
                        </Button>
                      );
                    }
                  })}
                </div>
              </ButtonGroup>
            </div>
          </div>
        </Col>
      </Row>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Selected Word:{" "}
            <span style={{ color: "gray", fontSize: "18px" }}>
              {selectedWord.word}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedWord && (
            <div>
              {Object.entries(selectedWord)?.map(([key, value]) => (
                <div key={key}>
                  <strong style={{ fontSize: "15px" }}>{key} : </strong> {value}
                </div>
              ))}
            </div>
          )}
          <hr style={{ width: "100%" }} />
          <div
            style={{ marginTop: "5%", marginLeft: "60%" }}
            onClick={handleCheckLevel}
          >
            <Eye size="15px" className="dropdown-item-icon" /> Check Word
          </div>
          {showLevels && word && !loading ? (
            <div>
              {Object.entries(word)?.map(([key, value]) => (
                <div key={key}>
                  <strong style={{ fontSize: "15px" }}>{key} : </strong> {value}
                </div>
              ))}
            </div>
          ) : loading ? (
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spinner animation="grow"></Spinner>
            </span>
          ) : (
            <div>Word not found</div>
          )}

          <hr style={{ width: "100%" }} />

          <div
            style={{ marginTop: "5%", marginLeft: "80%" }}
            // onClick={handleEditClick}
          >
            <Edit size="15px" className="dropdown-item-icon" /> Edit
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={approveDataToJsonFile}>
            Approve
          </Button>
          <Button variant="primary" onClick={checkDataToJsonFile}>
            Check
          </Button>
          <Button variant="" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default WordsAnalysis;
