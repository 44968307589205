// import node module libraries
import { Fragment, useState, useEffect, useRef } from "react";
import {
  Form,
  FormControl,
  InputGroup,
  Button,
  Offcanvas,
  Image,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

// import custom components
import AutocompleteTeacher from "components/dashboard/class/AutocompliteTeacher";
import {
  createBoardAction,
  editBoardAction,
  fetchBoardAction,
} from "actions/boardKanban";
import { Plus, Trash } from "react-feather";
import { fetchAllTeachersAction } from "actions/teachers";
import { useSearchParams } from "react-router-dom";

const AddNewBoard = ({ boardId, setBoardId, showBoard, setShowBoard }) => {
  const [searchParams] = useSearchParams();
  const template = searchParams.get("template");
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const { loading } = useSelector((state) => state.auth);
  const { board: boardEdited } = useSelector((state) => state.boards);
  const dispatch = useDispatch();
  const [boardData, setBoardData] = useState({
    title: "",
    description: "",
    owner: userData._id,
    members: [],
    type: "",
  });
  useEffect(() => {
    const userDataString = localStorage.getItem("userData");
    const userData = JSON.parse(userDataString);
    if (userData) {
      setBoardData((prevState) => ({
        ...prevState,
        owner: userData._id,
      }));
    }
  }, []);
  const handleAddBoard = async (e) => {
    if (boardId) {
      dispatch(editBoardAction({ ...boardData }, boardId, handleClose));
    } else {
      dispatch(
        createBoardAction(
          { ...boardData, template: template == "true" ? true : false },
          handleClose
        )
      );
    }
    e.preventDefault();
  };

  useEffect(() => {
    if (boardId) {
      dispatch(fetchBoardAction(boardId));
    }
  }, [dispatch, boardId]);
  useEffect(() => {
    if (boardEdited?._id) {
      setBoardData({
        title: boardEdited.title,
        description: boardEdited.description,
        owner: boardEdited.owner,
        members: boardEdited?.members?.map((member) => member),
        type: boardEdited.type,
      });
    }
  }, [boardEdited]);
  const handleClose = () => {
    setBoardData({
      title: "",
      description: "",
      owner: userData._id,
      members: [],
      type: "",
    });
    setShowBoard(false);
    setBoardId(null);
  };

  const handleShow = () => {
    setBoardData({
      title: "",
      description: "",
      owner: userData._id,
      members: [],
      type: "",
    });
    setShowBoard(true);
  };

  const { teachers } = useSelector((state) => state.teachers);
  useEffect(() => {
    dispatch(fetchAllTeachersAction());
  }, [dispatch]);

  return (
    <Fragment>
      {/* <Button variant="primary" onClick={() => handleShow()}>
        {template === "true" ? "Add New Template" : "Add New Board"}
      </Button> */}

      <Offcanvas show={showBoard} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {boardId ? "Update Board" : "Add New Board"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            {/* Title  */}
            <Form.Group className="mb-3">
              <Form.Label>Board Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Board Title"
                id="title"
                required
                value={boardData.title}
                onChange={(e) =>
                  setBoardData({ ...boardData, title: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Board Description</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                placeholder="Board Description"
                id="desc"
                required
                value={boardData.description}
                onChange={(e) =>
                  setBoardData({ ...boardData, description: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Project Type</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  id="general"
                  name="boardType"
                  label="General"
                  checked={boardData.type === "general"}
                  onChange={() =>
                    setBoardData({ ...boardData, type: "general" })
                  }
                />
                <Form.Check
                  type="radio"
                  id="teaching"
                  name="boardType"
                  label="Teaching"
                  checked={boardData.type === "teaching"}
                  onChange={() =>
                    setBoardData({ ...boardData, type: "teaching" })
                  }
                />
                <Form.Check
                  type="radio"
                  id="administration"
                  name="boardType"
                  label="Administration"
                  checked={boardData.type === "administration"}
                  onChange={() =>
                    setBoardData({ ...boardData, type: "administration" })
                  }
                />
              </div>
            </Form.Group>
            {/* <Form.Group>
                            <Form.Label>Members</Form.Label>
                            {boardData.members.map((memberId, index) => (
                                <div key={index} className="d-flex align-items-center mb-2">
                                    <Form.Select
                                        value={memberId.userId}
                                        onChange={(e) => handleMemberInputChange(index, e.target.value)}
                                    >
                                        <option value="">Select a member</option>
                                        {teachers.map((teacher) => (
                                            <option key={teacher._id} value={teacher._id}>
                                              {teacher.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Button
                                        variant="danger"
                                        className="ms-1"
                                        onClick={() => handleRemoveMemberInput(index)}
                                    >
                                        <Trash size="15px" className="dropdown-item-icon" color='white' />
                                    </Button>
                                </div>
                            ))}
                            <Button variant="info" className="mt-1" onClick={handleAddMemberInput}>
                                <Plus style={{ marginLeft: "-25%" }} />
                                Add
                            </Button>
                        </Form.Group> */}

            <div className="mt-3">
              <Button
                type="submit"
                variant="primary"
                id="add-new-event-btn"
                disabled={loading}
                onClick={(e) => handleAddBoard(e)}
              >
                {boardId ? "Update Board" : "Add New Board"}
              </Button>

              <Button
                className="ms-2"
                variant="outline-secondary"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  );
};

export default AddNewBoard;
