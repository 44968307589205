// import node module libraries
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Draggable } from "react-beautiful-dnd";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Dropdown,
  Card,
  Badge,
  Form,
  Modal,
  Button,
  Spinner,
} from "react-bootstrap";

// import custom components
import { Avatar } from "components/elements/bootstrap/Avatar";

// import sub custom components
import KanbanModal from "./KanbanModal";

// import context file
import { TaskKanbanContext } from "context/Context";
import { reformatCommentDate } from "helper/utils";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import {
  archivedCardAction,
  deleteCardAction,
  editCardAction,
  fetchCardAction,
} from "actions/boardCard";
import { createCommentAction } from "actions/boardComment";
import AvatarCom from "assets/images/avatar/avatar_com.webp";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { fetchBoardAction, getMembersAction } from "actions/boardKanban";
import { Archive } from "react-feather";

import { BookmarkFill } from "react-bootstrap-icons";
import LogGraph from "../task-kanban/LogGraph";
import TaskDescription from "../task-kanban/KanbanTaskDesc";
import { fetchOneQuizAction } from "actions/quiz";
import TaskDetails from "./TaskDetails";

const KanbanTaskStudent = (props) => {
  const navigate = useNavigate();
  const { task, index, cardId, setCardId, setShowModal, showModal } = props;

  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const [idOfCard, setIdOfCard] = useState(null);
  const [idOfList, setIdOfList] = useState(null);
  const [showCheckLog, setShowCheckLog] = useState(false);
  const { id: idOfBoard } = useParams();
  const buttonText = "Add Comment";
  const dispatch = useDispatch();
  const {
    TaskKanbanState: { teamMembers },
  } = useContext(TaskKanbanContext);
  const { board } = useSelector((state) => state.boards);
  useEffect(() => {
    if (idOfBoard) {
      dispatch(fetchBoardAction(idOfBoard));
    }
  }, [idOfBoard]);
  const { card } = useSelector((state) => state.boards);
  const { loading } = useSelector((state) => state.auth);
  const handleCheckLog = (cardId) => {
    setIdOfCard(cardId);
    setShowCheckLog(true);
  };
  const handleViewDetails = (cardId, listId) => {
    setIdOfCard(cardId);
    setIdOfList(listId);
    dispatch(fetchCardAction(cardId));
    setShowDetailsModal(true);
  };

  //archived TAsk

  const handleArchivedTask = (cardId) => {
    setTaskToArchive(cardId);
    setShowArchiveModal(true);
  };

  const confirmArchiveTask = () => {
    if (taskToArchive) {
      dispatch(
        archivedCardAction({ archived: true }, taskToArchive, idOfBoard)
      );
      setTaskToArchive(null);
      setShowArchiveModal(false);
      setConfirmationMessage("");
    }
  };

  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [taskToArchive, setTaskToArchive] = useState(null);

  //Delete Task

  const confirmDeleteTask = () => {
    if (taskToDelete && confirmationMessage === "DELETE TASK") {
      dispatch(deleteCardAction(taskToDelete, idOfBoard));
      setTaskToDelete(null);
      setShowDeleteModal(false);
      setConfirmationMessage("");
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const [cardData, setCardData] = useState({
    title: "",
    description: "",
    descStudent: "",
    acceptanceCriteria: "",
    acceptanceCriteriaStudent: "",
    listId: idOfList,
    boardId: idOfBoard,
    assignedTo: "",
    dueDate: "",
    position: "",
    border: "",
    type: "",
    onlyMeUser: "",
    inviteTeacher: false,
    quizId: null,
  });
  useEffect(() => {
    if (card?._id) {
      setCardData({
        title: card.title,
        description: card.description,
        descStudent: card.descStudent,
        acceptanceCriteria: card.acceptanceCriteria,
        acceptanceCriteriaStudent: card.acceptanceCriteriaStudent,
        listId: card.listId,
        boardId: card.boarId,
        dueDate: card.dueDate,
        assignedTo: card.assignedTo,
        position: card.position,
        border: card.border,
        type: card.type,
        onlyMeUser: userData._id,
        inviteTeacher: card.inviteTeacher,
        quizId: card.quizId,
      });
      // setDescription(card.description);
      // setDescSdescStudent(card.descStudent);
      // setCretiriaStudent(card.acceptanceCriteriaStudent);
      // setCretiriaTeacher(card.acceptanceCriteria);
    }
  }, [card]);
  const handleAddTask = async (e) => {
    dispatch(
      editCardAction(
        { ...cardData, id: idOfCard, listId: idOfList, boardId: idOfBoard },
        handleClose,
        idOfCard,
        idOfBoard
      )
    );

    e.preventDefault();
  };

  const handleClose = () => {
    setCardData({
      title: "",
      description: "",
      descStudent: "",
      acceptanceCriteria: "",
      acceptanceCriteriaStudent: "",
      listId: idOfList,
      boardId: idOfBoard,
      assignedTo: "",
      dueDate: "",
      position: "",
      border: "",
      type: "",
      onlyMeUser: "",
      inviteTeacher: false,
      quizId: null,
    });
    setShowDetailsModal(false);
    setIdOfCard(null);
    setIdOfList(null);
    setEditingDescription(false);
    setEditingStudentDesc(false);
    setEditingCriteria(false);
    setEditingCriteriaStudent(false);
  };

  useEffect(() => {
    dispatch(getMembersAction(idOfBoard));
  }, [dispatch, idOfBoard]);

  //Comment part
  const inputRef = useRef(null);

  const handleButtonClick = () => {
    // Scroll to the input field
    inputRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const [isCommenting, setIsCommenting] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [commentData, setCommentData] = useState({
    text: "",
    cardId: idOfCard,
    authorId: userData._id,
  });
  useEffect(() => {
    if (card) {
      setCommentData((prevState) => ({
        ...prevState,
        cardId: card._id,
      }));
    }
  }, [card?._id]);
  useEffect(() => {
    const userDataString = localStorage.getItem("userData");
    const userData = JSON.parse(userDataString);
    // const userData = { _id: "652a6d801e41855bd07db017" };
    if (userData) {
      setCommentData((prevState) => ({
        ...prevState,
        authorId: userData._id,
      }));
    }
  }, []);

  const toggleComments = () => {
    setShowComments(!showComments);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      className="btn-icon btn btn-ghost btn-sm rounded-circle"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));

  const handleInputClick = () => {
    setIsCommenting(true);
  };
  const handleSaveComment = (e) => {
    dispatch(createCommentAction(commentData, handleCloseComment, idOfBoard));
    setShowComments(true);
    e.preventDefault();
  };
  const handleCloseComment = () => {
    setCommentData({
      text: "",
      cardId: idOfCard,
      authorId: userData._id,
    });
    setIsCommenting(false);
    setCommentText("");
  };
  const [showComments, setShowComments] = useState(false);

  const ActionMenu = () => {
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <i className="fe fe-more-horizontal fs-4"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu align={"end"}>
          <Dropdown.Item
            eventKey="3"
            className="d-flex align-items-center"
            onClick={() => handleViewDetails(task?._id, task?.listId)}
          >
            <i className="dropdown-item-icon fe fe-eye"></i>View Details
          </Dropdown.Item>
          {/* <Dropdown.Item eventKey="4" className="d-flex align-items-center">
						  <i className="dropdown-item-icon fe fe-maximize-2"></i>Open in New
						  Tab
					  </Dropdown.Item> */}
          {/* <Dropdown.Item eventKey="5" className="d-flex align-items-center">
						  <i className="dropdown-item-icon fe fe-copy"></i>Duplicate task
					  </Dropdown.Item> */}
          {userData?.role !== "student" ? (
            <Dropdown.Item
              eventKey="6"
              className="d-flex align-items-center"
              onClick={() => handleCheckLog(task?._id)}
            >
              <i className="dropdown-item-icon fe fe-check"></i>Check graph log
            </Dropdown.Item>
          ) : null}
          {userData?._id === task.boardId?.owner ||
          userData?.role === "admin" ||
          userData?.role === "learning-architect" ? (
            <Dropdown.Item
              eventKey="7"
              className="d-flex align-items-center"
              onClick={() => handleArchivedTask(task?._id)}
            >
              <i className="dropdown-item-icon fe fe-archive"></i>Archived task
            </Dropdown.Item>
          ) : null}
        </Dropdown.Menu>
      </Dropdown>
    );
  };
  //quiz

  const { quiz } = useSelector((state) => state.quizs);
  useEffect(() => {
    if (cardData?.quizId) {
      dispatch(fetchOneQuizAction(cardData?.quizId));
    }
  }, [cardData]);
  return (
    <Fragment>
      {/* task kanban */}
      <div className="task-kanban">
        <div id="do">
          <Draggable draggableId={`${task?._id}`} index={index}>
            {(provided) => (
              <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
              >
                <Card style={{ borderLeft: `10px solid ${task?.border}` }}>
                  <Card.Body className="p-3">
                    <div className="d-flex justify-content-between">
                      <div
                        onClick={() =>
                          handleViewDetails(task?._id, task?.listId)
                        }
                      >
                        <span className="h5">{task?.title}</span>

                        <Badge
                          style={{
                            width: "24px",
                            height: "24px",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          bg={
                            task?.position === "Must"
                              ? "danger"
                              : task?.position === "Should"
                              ? "should-hedi"
                              : task?.position === "Could"
                              ? "info"
                              : task?.position === "Won't"
                              ? "warning"
                              : ""
                          }
                          className="me-1"
                        >
                          {task?.position === "Must"
                            ? "M"
                            : task?.position === "Should"
                            ? "S"
                            : task?.position === "Could"
                            ? "C"
                            : task?.position === "Won't"
                            ? "W"
                            : ""}
                        </Badge>
                      </div>
                      <div>
                        {/* action dropdown */}
                        <ActionMenu />
                        <KanbanModal
                          showModal={showModal}
                          columnno={-1}
                          cardId={cardId}
                          setCardId={setCardId}
                          setShowModal={setShowModal}
                        />
                      </div>
                    </div>
                    {task.type === "meta" && !task.cardParent && (
                      <div className="position-absolute top-0 end-0 mt-1 me-2">
                        <BookmarkFill size={"18px"} style={{ color: "gold" }} />
                      </div>
                    )}
                    {task?.assignedTo && (
                      <div className="ms-2 mt-2">
                        <span className="fs-6">
                          <i className="fe fe-user text-muted me-1"></i>
                          {task?.assignedTo?.name}
                        </span>
                        <span
                          className="align-middle"
                          style={{ marginLeft: "35%" }}
                        >
                          <i className="fe fe-message-square fs-6 text-muted"></i>{" "}
                          <span className="fw-semi-bold fs-6">
                            {task?.comments?.length}
                          </span>
                        </span>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </div>
            )}
          </Draggable>
        </div>
      </div>
      {/* Delete Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this task?</p>
          <h4 className="text-secondary">
            you must write "DELETE TASK" to confirm the deletion
          </h4>
          <input
            className="form-control"
            rows="3"
            placeholder="Enter confirmation message"
            value={confirmationMessage}
            onChange={(e) => setConfirmationMessage(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDeleteTask}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Task Log */}

      <Modal
        show={showCheckLog}
        onHide={() => setShowCheckLog(false)}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Task Log</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center overflow-auto">
          <LogGraph id={idOfCard} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCheckLog(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Archive Modal */}
      <Modal show={showArchiveModal} onHide={() => setShowArchiveModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Archived</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to archive this task?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowArchiveModal(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmArchiveTask}>
            Archived
          </Button>
        </Modal.Footer>
      </Modal>

      {/* View Details Modal */}
      <Modal
        fullscreen={true}
        show={showDetailsModal}
        onHide={() => setShowDetailsModal(false)}
        // className='modal-dialog modal-lg mt-40 mb-30'
        style={{ marginRight: "1%", overflowY: "scroll" }}
      >
        <Modal.Header
          closeButton
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ width: "76%" }}>
            <Modal.Title style={{ fontSize: "14px" }}>
              <Archive /> {card?.boardId?.title}
              <Form.Control
                as="textarea"
                rows="1"
                style={{
                  marginTop: "1%",
                  height: "fit-content",
                  width: "100%",
                  fontSize: "18px",
                  color: "black",
                  border: "none",
                  outline: "none",
                  fontWeight: "bold",
                }}
                value={cardData?.title}
                readOnly={userData._id !== card.createdBy}
                onChange={(e) => {
                  if (userData._id === card.createdBy) {
                    setCardData({ ...cardData, title: e.target.value });
                  }
                }}
              ></Form.Control>
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  alignItems: "center",
                  marginBottom: "2%",
                  marginTop: "1%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "2%",
                    marginLeft: "2%",
                    paddingBottom: "2%",
                  }}
                >
                  <i className="fe fe-message-square fs-6 text-muted"></i>{" "}
                  <span
                    className="fw-semi-bold fs-6"
                    style={{ marginRight: "10px", marginLeft: "10px" }}
                  >
                    {card?.comments?.length} Comments
                  </span>
                  <Button
                    variant="secondary"
                    onClick={handleButtonClick}
                    style={{
                      padding: "0.25rem 0.5rem",
                      fontSize: buttonText.length > 10 ? "0.75rem" : "1rem",
                    }}
                  >
                    {buttonText}
                  </Button>
                </div>
                <div
                  style={{
                    marginLeft: "2%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <strong>Due Date:</strong>
                  <input
                    style={{
                      width: "60%",
                      border: "none",
                      outline: "none",
                    }}
                    type="date"
                    className="form-control"
                    value={
                      cardData.dueDate ? cardData.dueDate.split("T")[0] : ""
                    }
                    onChange={(e) =>
                      setCardData({ ...cardData, dueDate: e.target.value })
                    }
                  />
                </div>

                {task?.type === "onlyMe" && (
                  <Form.Check
                    style={{
                      marginLeft: "1%",
                      width: "130px",
                    }}
                    type="checkbox"
                    label="Invite Teacher"
                    checked={cardData.inviteTeacher}
                    onChange={(e) =>
                      setCardData({
                        ...cardData,
                        inviteTeacher: e.target.checked,
                      })
                    }
                  />
                )}
                {task.quizId && (
                  <div>
                    <h5
                      style={{
                        width: "320px ",
                        position: "relative",
                      }}
                    >
                      <strong>Quiz:</strong> {" " + quiz?.title}
                      <i
                        style={{
                          position: "absolute",
                          zIndex: 999,
                          right: -60,
                          top: 5,
                          cursor: "pointer",
                          display: "flex",
                          color: "green",
                        }}
                        onClick={() =>
                          navigate(
                            `/quiz/quiz-finish?quiz_id=${quiz._id}&user_id=${userData.quiz_id}`
                          )
                        }
                        className="fe fe-file-text fs-6 text-muted"
                      >
                        <p
                          style={{
                            fontFamily: "cursive",
                            fontSize: 14,
                            color: "green",
                            marginLeft: 5,
                          }}
                        >
                          result
                        </p>
                      </i>
                    </h5>
                  </div>
                )}
              </div>
            </Modal.Title>
          </div>
          <div style={{ width: "28%", marginLeft: "20%", marginTop: "5%" }}>
            <span className="align-middle">
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* Button Container */}
                <button
                  disabled={loading}
                  className="btn btn-info"
                  onClick={handleAddTask}
                  style={{
                    backgroundColor: "red",
                    marginRight: "2%",
                    borderColor: "red",
                    width: "fit-content",
                  }}
                >
                  {loading ? (
                    <Spinner style={{ height: "15px", width: "15px" }} />
                  ) : (
                    <>
                      <i
                        className="fe fe-save"
                        style={{ marginRight: "5px" }}
                      ></i>
                      Save and Close
                    </>
                  )}
                </button>
              </div>
            </span>
          </div>
        </Modal.Header>
        <div className="modal-content">
          <Modal.Body
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ width: "68%" }}>
              {/* <TaskDescription card={card} board={board} /> */}
              {/* <hr /> */}
              <p>
                <strong>Discussion:</strong>{" "}
                <button
                  onClick={toggleComments}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  {showComments ? "↑" : "↓"}
                </button>
              </p>
              <div
                className="form-group"
                ref={inputRef}
                style={{ paddingBottom: "1%" }}
              >
                <Form onSubmit={(e) => handleSaveComment(e)}>
                  <Form.Control
                    type="text"
                    placeholder="Add a comment"
                    onClick={handleInputClick}
                    style={{
                      height: "fit-content",
                      marginTop: "3%",
                      height: "fit-content",
                      color: "black",
                      border: "none",
                      outline: "none",
                    }}
                    value={isCommenting ? commentData?.text : ""}
                    onChange={(e) => {
                      setCommentData({ ...commentData, text: e.target.value });
                      setCommentText(e.target.value);
                    }}
                  ></Form.Control>
                </Form>
              </div>
              {isCommenting && (
                <div style={{ marginTop: "2%", paddingBottom: "3%" }}>
                  <button
                    className="btn btn-info"
                    onClick={(e) => handleSaveComment(e)}
                    disabled={!commentText.trim()}
                    style={{
                      backgroundColor: commentText.trim() ? "" : "#D6D6D6",
                      marginRight: "2%",
                      borderColor: commentText.trim() ? "" : "#D6D6D6",
                    }}
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={() => handleCloseComment()}
                    style={{
                      backgroundColor: "#D6D6D6",
                      borderColor: "#D6D6D6",
                    }}
                  >
                    Cancel
                  </button>
                </div>
              )}
              {showComments &&
                card?.comments?.map((comment) => (
                  <div
                    key={comment?._id}
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      padding: "10px",
                      marginBottom: "20px",
                    }}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <div style={{ display: "flex" }}>
                        <div>
                          <p style={{ fontWeight: "bold" }}>
                            {comment?.authorId?.name}
                          </p>
                        </div>
                        <div style={{ marginLeft: "3%" }}>
                          <p>
                            <u>
                              <small>
                                {reformatCommentDate(
                                  comment?.createdAt
                                ).toLocaleString()}
                              </small>
                            </u>
                          </p>
                        </div>
                      </div>
                      <p> {comment?.text}</p>
                    </div>
                  </div>
                ))}
            </div>
            <div style={{ width: "25%" }}>
              <p>
                <strong>Last update</strong>{" "}
                {new Date(card?.updatedAt).toLocaleString()}
              </p>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      {/* <TaskDetails
     
        task={task}
      
       
      /> */}
      <ToastContainer />
    </Fragment>
  );
};

export default KanbanTaskStudent;
