// import node module libraries
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { Draggable } from "react-beautiful-dnd";
import { Link, useParams } from "react-router-dom";
import {
  Dropdown,
  Card,
  Badge,
  Form,
  Modal,
  Button,
  FormSelect,
  Spinner,
  FormControl,
} from "react-bootstrap";

// import custom components
import { Avatar } from "components/elements/bootstrap/Avatar";

// import sub custom components
import KanbanModal from "./KanbanModal";

// import context file
import { TaskKanbanContext } from "context/Context";
import { generateText, reformatCommentDate } from "helper/utils";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import {
  archivedCardAction,
  deleteCardAction,
  downloadCard,
  editCardAction,
  fetchCardAction,
  genrateQuizToActivityAction,
} from "actions/boardCard";
import { createCommentAction } from "actions/boardComment";
import AvatarCom from "assets/images/avatar/avatar_com.webp";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { fetchBoardAction, getMembersAction } from "actions/boardKanban";
import { Archive } from "react-feather";
import LogGraph from "./LogGraph";
import { Typeahead } from "react-bootstrap-typeahead";
import { BookmarkFill } from "react-bootstrap-icons";
import TaskDescription from "./KanbanTaskDesc";
import {
  fetchAllQuizsAction,
  fetchAllresultsInTask,
  fetchOneQuizAction,
} from "actions/quiz";
import QuizesInCard from "components/marketing/student/quiz-start/QuizesInCard";
import { getPointsAction, getPointsActionByIds } from "actions/curriculum";
import { FETCH_POINTS_BY_IDS } from "actions/constants";
import { splitTextAction } from "actions/words";
import WordsAnalysis from "./board-kanban/WordsAnalysis";
import SelectUserModal from "./SelectUserModal";

const KanbanTask = (props) => {
  const {
    task,
    index,
    cardId,
    setCardId,
    setShowModal,
    showModal,
    setMetaId,
    setShowMetaModal,
  } = props;
  const { cardDesc } = useSelector((state) => state.boards);
  const [showResultsModal, setShowResultsModal] = useState(false);
  const [showWordsModal, setShowWordsModal] = useState(false);

  const [description, setDescription] = useState("");
  const [cretiriaTeacher, setCretiriaTeacher] = useState("");
  const [cretiriaStudent, setCretiriaStudent] = useState("");
  const [descStudent, setDescSdescStudent] = useState("");
  const [editingDescription, setEditingDescription] = useState(false);
  const [editingCriteria, setEditingCriteria] = useState(false);
  const [editingCriteriaStudent, setEditingCriteriaStudent] = useState(false);
  const [editingStudentDesc, setEditingStudentDesc] = useState(false);
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const [idOfCard, setIdOfCard] = useState(null);
  const [idOfList, setIdOfList] = useState(null);
  const [showCheckLog, setShowCheckLog] = useState(false);
  const { id: idOfBoard } = useParams();
  const buttonText = "Add Comment";
  const dispatch = useDispatch();
  const {
    TaskKanbanState: { teamMembers },
    TaskKanbanDispatch,
  } = useContext(TaskKanbanContext);

  const { board } = useSelector((state) => state.boards);

  const { card } = useSelector((state) => state.boards);
  const { loading } = useSelector((state) => state.auth);
  const handleCheckLog = (cardId) => {
    setIdOfCard(cardId);
    setShowCheckLog(true);
  };
  const handleViewDetails = (cardId, listId) => {
    setIdOfCard(cardId);
    setIdOfList(listId);
    dispatch(fetchCardAction(cardId));
    setShowDetailsModal(true);
  };

  //archived TAsk

  const handleArchivedTask = (cardId) => {
    setTaskToArchive(cardId);
    setShowArchiveModal(true);
  };
  const handleExportTask = (cardId) => {
    dispatch(downloadCard(cardId));
  };

  const confirmArchiveTask = () => {
    if (taskToArchive) {
      dispatch(
        archivedCardAction({ archived: true }, taskToArchive, idOfBoard)
      );
      setTaskToArchive(null);
      setShowArchiveModal(false);
      setConfirmationMessage("");
    }
  };

  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [taskToArchive, setTaskToArchive] = useState(null);

  const confirmDeleteTask = () => {
    if (taskToDelete && confirmationMessage === "DELETE TASK") {
      dispatch(deleteCardAction(taskToDelete, idOfBoard));
      setTaskToDelete(null);
      setShowDeleteModal(false);
      setConfirmationMessage("");
    }
  };
  const [updateQuiz, setUpdateQuiz] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const [cardData, setCardData] = useState({
    title: "",
    listId: idOfList,
    boardId: idOfBoard,
    assignedTo: "",
    dueDate: "",
    position: "",
    border: "",
    type: "",
    onlyMeUser: "",
    inviteTeacher: false,
    quizId: null,
    covredPoints: [],
    duration: "0 Hr",
  });
  const [logs, setLogs] = useState([]);
  useEffect(() => {
    if (card?._id) {
      setCardData({
        title: card.title,
        listId: card.listId,
        boardId: card.boarId,
        dueDate: card.dueDate,
        assignedTo: card.assignedTo,
        position: card.position,
        border: card.border,
        type: card.type,
        onlyMeUser: userData._id,
        inviteTeacher: card.inviteTeacher,
        quizId: card.quizId,
        covredPoints: card.covredPoints || [],
        duration: card.duration || "0 Hr",
      });
      setDescription(card.description);
      setDescSdescStudent(card.descStudent);
      setCretiriaStudent(card.acceptanceCriteriaStudent);
      setCretiriaTeacher(card.acceptanceCriteria);
    }
  }, [card]);
  const handleClose = () => {
    setCardData({
      title: "",
      listId: idOfList,
      boardId: idOfBoard,
      assignedTo: "",
      dueDate: "",
      position: "",
      border: "",
      type: "",
      onlyMeUser: "",
      inviteTeacher: false,
      quizId: null,
      covredPoints: [],
    });
    setShowDetailsModal(false);
    setIdOfCard(null);
    setIdOfList(null);
    setEditingDescription(false);
    setEditingStudentDesc(false);
    setEditingCriteria(false);
    setEditingCriteriaStudent(false);
    setUpdateQuiz(false);
    setLogs([]);
  };
  // meta modal

  const handleAddTask = async (e) => {
    dispatch(
      editCardAction(
        { ...cardData, id: idOfCard, listId: idOfList, boardId: idOfBoard },
        handleClose,
        idOfCard,
        idOfBoard,
        logs,
        setLogs
      )
    );
    e.preventDefault();
  };
  const handleSelect = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };
  const { members } = useSelector((state) => state.boards);

  // useEffect(() => {
  //   dispatch(getMembersAction(idOfBoard));
  // }, [dispatch, idOfBoard]);

  //Comment part
  const inputRef = useRef(null);

  const [isCommenting, setIsCommenting] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [commentData, setCommentData] = useState({
    text: "",
    cardId: idOfCard,
    authorId: userData._id,
  });
  useEffect(() => {
    if (card) {
      setCommentData((prevState) => ({
        ...prevState,
        cardId: card._id,
      }));
    }
  }, [card?._id]);
  useEffect(() => {
    const userDataString = localStorage.getItem("userData");
    const userData = JSON.parse(userDataString);
    if (userData) {
      setCommentData((prevState) => ({
        ...prevState,
        authorId: userData._id,
      }));
    }
  }, []);

  const toggleComments = () => {
    setShowComments(!showComments);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      className="btn-icon btn btn-ghost btn-sm rounded-circle"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));

  const handleInputClick = () => {
    setIsCommenting(true);
  };
  const handleSaveComment = (e) => {
    dispatch(createCommentAction(commentData, handleCloseComment, idOfBoard));
    setShowComments(true);
    e.preventDefault();
  };
  const handleCloseComment = () => {
    setCommentData({
      text: "",
      cardId: idOfCard,
      authorId: userData._id,
    });
    setIsCommenting(false);
    setCommentText("");
  };
  const [showComments, setShowComments] = useState(false);

  //quizes

  const { quizs, quiz } = useSelector((state) => state.quizs);

  useEffect(() => {
    if (cardData?.quizId && showDetailsModal) {
      dispatch(fetchOneQuizAction(cardData?.quizId));
    }
  }, [cardData, showDetailsModal]);
  const handleSelection = (selected) => {
    if (selected.length > 0) {
      setCardData({ ...cardData, quizId: selected[0]._id });
    }
  };

  const ActionMenu = () => {
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <i className="fe fe-more-horizontal fs-4"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu align={"end"}>
          <Dropdown.Item
            eventKey="3"
            className="d-flex align-items-center"
            onClick={() => handleViewDetails(task?._id, task?.listId)}
          >
            <i className="dropdown-item-icon fe fe-eye"></i>View Details
          </Dropdown.Item>

          {userData?.role !== "student" ? (
            <Dropdown.Item
              eventKey="6"
              className="d-flex align-items-center"
              onClick={() => handleCheckLog(task?._id)}
            >
              <i className="dropdown-item-icon fe fe-check"></i>Check graph log
            </Dropdown.Item>
          ) : null}
          {userData?._id === task.boardId?.owner ||
          userData?.role === "admin" ||
          userData?.role === "learning-architect" ? (
            <>
              <Dropdown.Item
                eventKey="7"
                className="d-flex align-items-center"
                onClick={() => handleArchivedTask(task?._id)}
              >
                <i className="dropdown-item-icon fe fe-archive"></i>Archive task
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="7"
                className="d-flex align-items-center"
                onClick={() => handleExportTask(task?._id)}
              >
                <i className="dropdown-item-icon fe fe-download"></i>Export
              </Dropdown.Item>
            </>
          ) : null}
          <Dropdown.Item
            eventKey="7"
            className="d-flex align-items-center"
            onClick={() => {
              setMetaId(task?._id), setShowMetaModal(true);
            }}
          >
            <i className="dropdown-item-icon fe fe-activity"></i>Create activity
            card
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const handleshowResults = () => {
    setShowResultsModal(true);
    dispatch(fetchAllresultsInTask(card._id, card.quizId));
  };

  //points
  const { points, taskPoints } = useSelector((state) => state.curriculum);

  useEffect(() => {
    if (cardData?.covredPoints.length > 0 && showDetailsModal) {
      dispatch(getPointsActionByIds(cardData?.covredPoints));
    } else {
      dispatch({ type: FETCH_POINTS_BY_IDS, payload: [] });
    }
  }, [cardData, showDetailsModal]);

  const handleSelectionPoints = (selected) => {
    if (selected.length > 0) {
      setCardData({
        ...cardData,
        covredPoints: [...cardData?.covredPoints, selected[0]._id],
      });
    }
  };

  const handleDeletePoint = (id) => {
    setCardData({
      ...cardData,
      covredPoints: cardData.covredPoints.filter((e) => e != id),
    });
  };

  const handleSplitText = () => {
    setShowWordsModal(true);
  };

  //AI genrator

  const handleGenrateQuiz = () => {
    const AIPoints = taskPoints.map((p) => {
      return {
        _id: p._id,
        title: p.title,
      };
    });

    dispatch(
      genrateQuizToActivityAction({
        covredPoints: AIPoints,
        activityDesc: JSON.stringify(cardDesc.map((desc) => desc.desc)),
      })
    );
  };
  return (
    <Fragment>
      {/* task kanban */}
      <div className="task-kanban">
        <div id="do">
          <Draggable draggableId={`${task?._id}`} index={index}>
            {(provided) => (
              <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
              >
                <Card style={{ borderLeft: `10px solid ${task?.border}` }}>
                  <Card.Body className="p-3">
                    <div className="d-flex justify-content-between">
                      <div
                        onClick={() =>
                          handleViewDetails(task?._id, task?.listId)
                        }
                      >
                        <span className="h5">{task?.title}</span>

                        <Badge
                          style={{
                            width: "24px",
                            height: "24px",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          bg={
                            task?.position === "Must"
                              ? "danger"
                              : task?.position === "Should"
                              ? "should-hedi"
                              : task?.position === "Could"
                              ? "info"
                              : task?.position === "Won't"
                              ? "warning"
                              : ""
                          }
                          className="me-1"
                        >
                          {task?.position === "Must"
                            ? "M"
                            : task?.position === "Should"
                            ? "S"
                            : task?.position === "Could"
                            ? "C"
                            : task?.position === "Won't"
                            ? "W"
                            : ""}
                        </Badge>
                      </div>
                      <div>
                        {/* action dropdown */}
                        <ActionMenu />
                        <KanbanModal
                          showModal={showModal}
                          columnno={-1}
                          cardId={cardId}
                          setCardId={setCardId}
                          setShowModal={setShowModal}
                        />
                      </div>
                    </div>
                    {task.type === "meta" && !task.cardParent && (
                      <div className="position-absolute top-0 end-0 mt-1 me-2">
                        <BookmarkFill size={"18px"} style={{ color: "gold" }} />
                      </div>
                    )}
                    {task?.assignedTo && (
                      <div className="ms-2 mt-2">
                        <span className="fs-6">
                          <i className="fe fe-user text-muted me-1"></i>
                          {task?.assignedTo?.name}
                        </span>
                        <span
                          className="align-middle"
                          style={{ marginLeft: "35%" }}
                        >
                          <i className="fe fe-message-square fs-6 text-muted"></i>{" "}
                          <span className="fw-semi-bold fs-6">
                            {task?.comments?.length}
                          </span>
                        </span>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </div>
            )}
          </Draggable>
        </div>
      </div>
      {/* Delete Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this task?</p>
          <h4 className="text-secondary">
            you must write "DELETE TASK" to confirm the deletion
          </h4>
          <input
            className="form-control"
            rows="3"
            placeholder="Enter confirmation message"
            value={confirmationMessage}
            onChange={(e) => setConfirmationMessage(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDeleteTask}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Task Log */}

      <Modal
        show={showCheckLog}
        onHide={() => setShowCheckLog(false)}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Task Log</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center overflow-auto">
          <LogGraph id={idOfCard} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCheckLog(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Archive Modal */}
      <Modal show={showArchiveModal} onHide={() => setShowArchiveModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Archiving</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to archive this task?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowArchiveModal(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmArchiveTask}>
            Archived
          </Button>
        </Modal.Footer>
      </Modal>

      {/* View Details Modal */}
      <Modal
        fullscreen={true}
        show={showDetailsModal}
        onHide={() => handleClose()}
        // className='modal-dialog modal-lg mt-40 mb-30'
        style={{ overflowY: "scroll", marginRight: "3%" }}
      >
        <Modal.Header
          // closeButton
          style={{
            zIndex: 1999,
            flexWrap: "wrap", // Allow wrapping on smaller screens
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <Modal.Title style={{ fontSize: "14px" }}>
              <Archive /> {card?.boardId?.title}
              <Form.Control
                as="textarea"
                rows="1"
                style={{
                  marginTop: "1%",
                  width: "100%",
                  fontSize: "16px",
                  color: "black",
                  border: "none",
                  outline: "none",
                  fontWeight: "bold",
                }}
                value={cardData?.title}
                onChange={(e) => {
                  setCardData({ ...cardData, title: e.target.value });
                  setLogs([...logs, "Task title has been  updated"]);
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginBottom: "1%",
                  marginTop: "1%",
                }}
              >
                <div className="header-line-1">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Avatar
                      size="md"
                      src={AvatarCom}
                      type="image"
                      className="rounded-circle"
                    />
                    <Form.Select
                      style={{
                        width: "100%", // Full width for mobile screens
                        marginLeft: "1%",
                        marginTop: "10px", // Add margin for spacing in mobile view
                      }}
                      value={cardData?.assignedTo}
                      onChange={(e) =>
                        setCardData({ ...cardData, assignedTo: e.target.value })
                      }
                    >
                      <option value="">
                        {cardData?.assignedTo?.name
                          ? cardData.assignedTo.name
                          : "Not Assigned"}
                      </option>
                      {members?.map((member) => (
                        <option key={member._id} value={member._id}>
                          {member.name}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between", // Adjust for mobile
                      width: "100%", // Ensure full width on mobile
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "nowrap",
                        width: "140px",
                        marginTop: "10px", // Add margin for better mobile spacing
                        marginLeft: "10px",
                      }}
                    >
                      <i className="fe fe-message-square fs-6 text-muted"></i>
                      <span
                        className="fw-semi-bold fs-6"
                        style={{ marginLeft: "10px" }}
                      >
                        {card?.comments?.length} Comments
                      </span>
                    </div>

                    <div
                      style={{
                        marginLeft: "1%",
                        display: "flex",
                        alignItems: "center",
                        width: "100%", // Adapt width for mobile
                        marginTop: "10px", // Add margin for mobile
                      }}
                    >
                      <span>Due Date:</span>
                      <input
                        style={{
                          width: "60%",
                          border: "none",
                          outline: "none",
                          marginLeft: "10px", // Better spacing on mobile
                        }}
                        type="date"
                        className="form-control"
                        value={
                          cardData.dueDate ? cardData.dueDate.split("T")[0] : ""
                        }
                        onChange={(e) => {
                          setCardData({ ...cardData, dueDate: e.target.value });
                          setLogs([...logs, "Task date has been  updated"]);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column", // Default for mobile (stack vertically)
                    marginTop: "10px",
                    width: "100%", // Full width on mobile
                  }}
                  className="header-Line-2"
                >
                  {/* Type dropdown */}
                  {board?.type === "teaching" &&
                    userData?._id === task?.boardId?.owner && (
                      <Form.Select
                        style={{
                          width: "100%", // Full width for mobile
                          marginLeft: "0%",
                          marginTop: "10px",
                          border: "none",
                          outline: "none",
                        }}
                        value={cardData?.type}
                        onChange={(e) => {
                          setCardData({ ...cardData, type: e.target.value });
                          setLogs([...logs, "Task type has been  updated"]);
                        }}
                      >
                        <option value="">Choose type</option>
                        <option value="classic">Classic</option>
                        <option value="onlyMe">Only Me</option>
                        <option value="meta">Class activity</option>
                      </Form.Select>
                    )}

                  {/* Quiz Typeahead */}
                  {task.type === "meta" && (!task.quizId || updateQuiz) && (
                    <Typeahead
                      id="quiz-typeahead"
                      labelKey="title"
                      options={quizs}
                      placeholder="Choose a quiz..."
                      style={{
                        width: "100%", // Full width on mobile
                        marginTop: "10px",
                      }}
                      onChange={handleSelection}
                    />
                  )}
                  {task.quizId && !updateQuiz && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "10px",
                        position: "relative",
                      }}
                    >
                      <h5
                        style={{
                          width: "100%", // Full width on smaller screens
                          position: "relative",
                        }}
                      >
                        {quiz?.title}
                        <i
                          style={{
                            position: "absolute",
                            zIndex: 999,
                            left: -15,
                            top: 5,
                            cursor: "pointer",
                          }}
                          onClick={() => setUpdateQuiz(true)}
                          className="fe fe-edit fs-6 text-muted"
                        ></i>
                      </h5>
                      <i
                        style={{
                          cursor: "pointer",
                          color: "green",
                          display: "flex",

                          position: "absolute",
                          zIndex: 999,
                          right: "40%",
                          top: 4,
                          cursor: "pointer",
                        }}
                        onClick={() => handleshowResults()}
                        className="fe fe-file-text fs-6 text-muted result"
                      >
                        <p
                          style={{
                            fontFamily: "cursive",
                            fontSize: 14,
                            color: "green",
                          }}
                        >
                          Results
                        </p>
                      </i>
                    </div>
                  )}
                  {/* AI Quiz Button */}
                  {userData.role === "admin" && (
                    <Button
                      style={{
                        marginLeft: "0%",
                        width: "100%",
                        marginTop: "10px",
                      }}
                      onClick={() => handleGenrateQuiz()}
                    >
                      AI Quiz
                    </Button>
                  )}

                  {/* Invite Teacher Checkbox */}
                  {task?.type === "onlyMe" && (
                    <Form.Check
                      style={{
                        marginLeft: "0%",
                        width: "100%",
                        marginTop: "10px",
                      }}
                      type="checkbox"
                      label="Invite Teacher"
                      checked={cardData.inviteTeacher}
                      onChange={(e) =>
                        setCardData({
                          ...cardData,
                          inviteTeacher: e.target.checked,
                        })
                      }
                    />
                  )}
                </div>

                {/* Media query for desktop to display the elements in a row */}
                <style>
                  {`
    @media (min-width: 768px) {
      .header-Line-2 {
        flex-direction: row !important; 
        align-items: center; 
        justify-content: space-between; 
        width:40% !important;
      .form-select, .btn {
        width: auto !important; 
        margin-top: 0 !important;
        margin-left: 10px; 
      }
        .result{
        right :-50% !important;
        top:5 !important;
        }
    }
        .header-line-1 {
         flex-direction: row !important; 
        align-items: center; 
        justify-content: space-between; 
        display:flex !important; 
        width:100% !important;
        }
  `}
                </style>
              </div>
            </Modal.Title>
          </div>

          {/* Buttons Section */}
          <div
            style={{
              width: "100%", // Full width on mobile
              display: "flex",
              justifyContent: "space-between", // Space between buttons
              alignItems: "center",
              flexWrap: "wrap", // Allow wrapping for smaller screens
            }}
          >
            <button
              disabled={loading}
              className="btn btn-info"
              onClick={handleSplitText}
              style={{
                marginRight: "1%",
                width: "140px",
                fontSize: "11px",
              }}
            >
              <>Check vocabulary</>
            </button>

            <button
              disabled={loading}
              className="btn btn-info"
              onClick={handleAddTask}
              style={{
                backgroundColor: "red",
                marginRight: "1%",
                borderColor: "red",
                width: "140px",
                fontSize: "11px",
              }}
            >
              {loading ? (
                <Spinner style={{ height: "15px", width: "15px" }} />
              ) : (
                <>
                  <i className="fe fe-save" style={{ marginRight: "5px" }}></i>
                  Save and Close
                </>
              )}
            </button>
          </div>
        </Modal.Header>

        <div className="modal-content">
          <Modal.Body
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              flexWrap: "wrap", // Allow content to wrap on smaller screens
            }}
          >
            <div
              style={{
                flex: "1 1 68%",
                minWidth: "300px",
                padding: "20px 10px",
                boxShadow:
                  "rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px",
                padding: "10px",
              }}
            >
              <TaskDescription card={card} board={board} />

              <p>
                <strong>Discussion:</strong>{" "}
                <button
                  onClick={toggleComments}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  {showComments ? "↑" : "↓"}
                </button>
              </p>
              <div
                className="form-group"
                ref={inputRef}
                style={{ paddingBottom: "1%" }}
              >
                <Form onSubmit={(e) => handleSaveComment(e)}>
                  <Form.Control
                    type="text"
                    placeholder="Add a comment"
                    onClick={handleInputClick}
                    style={{
                      height: "fit-content",
                      marginTop: "3%",
                      color: "black",
                      border: "none",
                      outline: "none",
                    }}
                    value={isCommenting ? commentData?.text : ""}
                    onChange={(e) => {
                      setCommentData({ ...commentData, text: e.target.value });
                      setCommentText(e.target.value);
                    }}
                  />
                </Form>
              </div>
              {isCommenting && (
                <div style={{ marginTop: "2%", paddingBottom: "3%" }}>
                  <button
                    className="btn btn-info"
                    onClick={(e) => handleSaveComment(e)}
                    disabled={!commentText.trim()}
                    style={{
                      backgroundColor: commentText.trim() ? "" : "#D6D6D6",
                      marginRight: "2%",
                      borderColor: commentText.trim() ? "" : "#D6D6D6",
                    }}
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={() => handleCloseComment()}
                    style={{
                      backgroundColor: "#D6D6D6",
                      borderColor: "#D6D6D6",
                    }}
                  >
                    Cancel
                  </button>
                </div>
              )}
              {showComments &&
                card?.comments?.map((comment) => (
                  <div
                    key={comment?._id}
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      padding: "10px",
                      marginBottom: "20px",
                    }}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <div style={{ display: "flex" }}>
                        <div>
                          <p style={{ fontWeight: "bold" }}>
                            {comment?.authorId?.name}
                          </p>
                        </div>
                        <div style={{ marginLeft: "3%" }}>
                          <p>
                            <u>
                              <small>
                                {reformatCommentDate(
                                  comment?.createdAt
                                ).toLocaleString()}
                              </small>
                            </u>
                          </p>
                        </div>
                      </div>
                      <p> {comment?.text}</p>
                    </div>
                  </div>
                ))}
            </div>
            <div
              style={{
                flex: "1 1 25%",
                minWidth: "300px",
                padding: "0 10px",
                boxShadow:
                  "rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px",
                padding: "20px",
              }}
            >
              <p>
                <strong>Last update</strong>{" "}
                {new Date(card?.updatedAt).toLocaleString()}
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "4%",
                }}
              >
                <p>
                  <strong>Priority:</strong>
                </p>
                <Form.Select
                  style={{
                    width: "60%", // Adjust width for better responsiveness
                    marginLeft: "3%",
                    marginTop: "-2%",
                    border:
                      cardData?.position === "Must"
                        ? "#ae302e"
                        : cardData?.position === "Should"
                        ? "#9fc947"
                        : cardData?.position === "Could"
                        ? "#0ea5e9"
                        : cardData?.position === "Won't"
                        ? "#f59e0b"
                        : "#fff",
                    outline: "none",
                    backgroundColor:
                      cardData?.position === "Must"
                        ? "#ae302e"
                        : cardData?.position === "Should"
                        ? "#9fc947"
                        : cardData?.position === "Could"
                        ? "#0ea5e9"
                        : cardData?.position === "Won't"
                        ? "#f59e0b"
                        : "gray",
                    color: "white",
                  }}
                  placeholder={
                    cardData?.position ? cardData.position : "Priority"
                  }
                  defaultValue={cardData?.position}
                  onChange={(e) => {
                    setCardData({ ...cardData, position: e.target.value });
                    setLogs([...logs, "Task priority has been  updated"]);
                  }}
                  required
                >
                  <option value="">
                    {cardData?.position ? cardData.position : "Priority"}
                  </option>
                  <option value="Must">Must</option>
                  <option value="Should">Should</option>
                  <option value="Could">Could</option>
                  <option value="Won't">Won't</option>
                </Form.Select>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "4%",
                }}
              >
                <p>
                  <strong>Duration:</strong>
                </p>
                <FormControl
                  style={{
                    width: "60%", // Adjust width for better responsiveness
                    marginLeft: "3%",
                    marginTop: "-2%",
                    border: "none",
                    outline: "none",
                  }}
                  value={cardData?.duration}
                  onChange={(e) =>
                    setCardData({ ...cardData, duration: e.target.value })
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "4%",
                }}
              >
                <p>
                  <strong>Covered Points :</strong>
                  <Typeahead
                    id="points-typeahead"
                    labelKey="title"
                    options={points}
                    placeholder="Choose a point"
                    onChange={handleSelectionPoints}
                  />
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "4%",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  width: "100%", // Use full width
                }}
              >
                {taskPoints?.length > 0 &&
                  taskPoints.map((p) => (
                    <Badge
                      key={p?._id}
                      style={{ marginTop: 10, display: "flex" }}
                      bg="secondary"
                    >
                      {p?.title}
                      <div
                        style={{ cursor: "pointer", marginLeft: 2 }}
                        onClick={() => handleDeletePoint(p?._id)}
                      >
                        <i className="fe fe-x fs-6"></i>
                      </div>
                    </Badge>
                  ))}
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        fullscreen={true}
        show={showResultsModal}
        onHide={() => setShowResultsModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Quiz Results</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QuizesInCard />
        </Modal.Body>
      </Modal>
      <Modal
        fullscreen={true}
        show={showWordsModal}
        onHide={() => setShowWordsModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title> Analytics </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WordsAnalysis title={cardData.title + "-by-" + userData.name} />
        </Modal.Body>
      </Modal>

      <ToastContainer />
    </Fragment>
  );
};

export default KanbanTask;
