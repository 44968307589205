import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import AppConfigReducer from "./AppConfigReducer";
import authReducer from "./AuthReducer";
import packsReducer from "./PacksReducer";
import usersReducer from "./UsersReducer";
import teachersReducer from "./TeachersReducer";
import booksReducer from "./BooksReducer";
import sessionsReducer from "./SessionsReducer";
import followedPackReducer from "./FollowedPackReducer";
import followedSessionReducer from "./FollowedSessionReducer";
import adminsReducer from "./AdminReducer";
import clientsReducer from "./ClientReducer";
import classesReducer from "./ClassesReducer";
import clientsInClassReducer from "./ClientInClassReducer";
import presenceReducer from "./PresenceReducer";
import clientsFromDataReducer from "./ClientFromDataReducer";
import assistantsReducer from "./AssistantReducer";
import clientDetailsReducer from "./ClientDetailsReducer";
import notesReducer from "./NotesReducer";
import packsFromDataReducer from "./PackFromDataReducer";
import smsReducer from "./smsReducer";
import readersReducer from "./ReaderReducer";
import checkSessionReducer from "./CheckSessionReducer";
import badgesReducer from "./BagdeReducer";
import logsReducer from "./LogsReducer";
import kanbanReducer from "./kanbanReducer";
import boardKanbanReducer from "./BoardKanbanReducer";
import curriculumReduce from "./CurriculumReduce";
import quizsReducer from "./QuizsReducer";
import WordReducer from "./WordReducer";
// Combine your reducers using combineReducers
const rootReducer = combineReducers({
  appConfig: AppConfigReducer,
  auth: authReducer,
  packs: packsReducer,
  users: usersReducer,
  teachers: teachersReducer,
  books: booksReducer,
  sessions: sessionsReducer,
  followedPack: followedPackReducer,
  followedSession: followedSessionReducer,
  admins: adminsReducer,
  assistants: assistantsReducer,
  clients: clientsReducer,
  classes: classesReducer,
  clientsInClasses: clientsInClassReducer,
  presences: presenceReducer,
  clientsFromData: clientsFromDataReducer,
  totalPages: clientsReducer,
  totalClassesPages: classesReducer,
  clientDetails: clientDetailsReducer,
  notes: notesReducer,
  packsFromData: packsFromDataReducer,
  smsState: smsReducer,
  readers: readersReducer,
  checkSession: checkSessionReducer,
  badges: badgesReducer,
  logs: logsReducer,
  kanbanTasks: kanbanReducer,
  boards: boardKanbanReducer,
  curriculum: curriculumReduce,
  quizs: quizsReducer,
  words: WordReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
