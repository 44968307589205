import {
  CREATE_CURRICULUM,
  CREATE_SUBJECT_IN_CURRIULUM,
  CREATE_TOPIC_IN_SUBJECT,
  DELETE_CURRICULUM,
  DELETE_SUBJECT,
  DELETE_TOPIC,
  FETCH_POINTS,
  FETCH_POINTS_BY_IDS,
  GET_ALL_CURRICULUM,
  UPDATE_CURRICULUM,
  UPDATE_SUBJECT,
  UPDATE_TOPIC,
} from "actions/constants";

const CurriculumReduce = (
  state = { curriculum: [], points: [], taskPoints: [] },
  action
) => {
  switch (action.type) {
    case GET_ALL_CURRICULUM:
      return { ...state, curriculum: action?.payload };
    case DELETE_CURRICULUM:
      return {
        ...state,
        curriculum: state.curriculum.filter((e) => e._id != action?.payload),
      };
    case CREATE_CURRICULUM:
      return { ...state, curriculum: [action.payload, ...state.curriculum] };
    case UPDATE_CURRICULUM:
      return {
        ...state,
        curriculum: state.curriculum.map((e) => {
          if (e._id == action.payload._id) {
            return action.payload;
          } else {
            return e;
          }
        }),
      };
    case CREATE_SUBJECT_IN_CURRIULUM:
      return {
        ...state,
        curriculum: state.curriculum.map((e) => {
          if (e._id == action.payload._id) {
            return action.payload;
          } else {
            return e;
          }
        }),
      };

    case DELETE_SUBJECT:
      return {
        ...state,
        curriculum: state.curriculum.map((e) => {
          if (e._id == action.payload.curriculumId) {
            return {
              ...e,
              subjects: e.subjects.filter((e) => e._id != action.payload.id),
            };
          } else {
            return e;
          }
        }),
      };
    case UPDATE_SUBJECT:
      return {
        ...state,
        curriculum: state.curriculum.map((e) => {
          if (e._id == action.payload.curriculumId) {
            return {
              ...e,
              subjects: e.subjects.map((s) => {
                if (s._id == action.payload.subject._id) {
                  return action.payload.subject;
                } else {
                  return s;
                }
              }),
            };
          } else {
            return e;
          }
        }),
      };
    case CREATE_TOPIC_IN_SUBJECT:
      return {
        ...state,
        curriculum: state.curriculum.map((c) => {
          return {
            ...c,
            subjects: c.subjects.map((s) => {
              if (s._id == action.payload._id) {
                return action.payload;
              } else {
                return s;
              }
            }),
          };
        }),
      };

    case DELETE_TOPIC:
      return {
        ...state,
        curriculum: state.curriculum.map((c) => {
          return {
            ...c,
            subjects: c.subjects.map((s) => {
              return {
                ...s,
                topics: s.topics.filter((t) => t._id != action.payload),
              };
            }),
          };
        }),
      };

    case UPDATE_TOPIC:
      return {
        ...state,
        curriculum: state.curriculum.map((c) => {
          return {
            ...c,
            subjects: c.subjects.map((s) => {
              return {
                ...s,
                topics: s.topics.map((t) => {
                  if (t._id == action.payload._id) {
                    return action.payload;
                  } else {
                    return t;
                  }
                }),
              };
            }),
          };
        }),
      };

    case FETCH_POINTS:
      return { ...state, points: action.payload };

    case FETCH_POINTS_BY_IDS:
      return { ...state, taskPoints: action.payload };

    default:
      return state;
  }
};

export default CurriculumReduce;
