import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getDescriptionById, editDescription, deleteDescription, createDescription, fetchDescriptionByCard, getCardById, updateBoardLog } from "api";
import {
    GET_DESC,
    LOADING,
    GET_DESC_BY_CARD,
    GET_CARD,
    DELETE_DESCRIPTION,
    CREATE_DESC,
    EDIT_DESC,

} from "./constants";
const userDataString = localStorage.getItem('userData');
const userData = JSON.parse(userDataString);
export const fetchDescriptionAction = (id) => async (dispatch) => {
    try {
        dispatch({ type: LOADING, payload: true });

        const { data } = await getDescriptionById(id);
        dispatch({ type: GET_DESC, payload: data });

        dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
            console.log(error.response.data.message);
            dispatch({ type: LOADING, payload: false });
        }
    }
};
export const fetchDescriptionByCardAction = (id) => async (dispatch) => {
    try {
        dispatch({ type: LOADING, payload: true });

        const { data } = await fetchDescriptionByCard(id);
        dispatch({ type: GET_DESC_BY_CARD, payload: data });

        dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
            console.log(error.response.data.message);
            dispatch({ type: LOADING, payload: false });
        }
    }
};
export const editDescriptionAction = (fromData, handleClose, setDescId, descId) => async (dispatch) => {
    try {
        dispatch({ type: LOADING, payload: true });
        const { data } = await editDescription(fromData, descId);
        dispatch({ type: EDIT_DESC, payload: data });
        handleClose()
        setDescId(null)
        dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message);
        }
        dispatch({ type: LOADING, payload: false });
    }
};
export const deleteDescriptionAction = (id) => async (dispatch) => {
    try {
        dispatch({ type: LOADING, payload: true });
        await deleteDescription(id);
        dispatch({ type: DELETE_DESCRIPTION, payload: id });
        dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
            console.log(error.response.data.message);
            dispatch({ type: LOADING, payload: false });
        }
    }
};
export const createDescriptionAction = (fromData, handleClose, boardId) => async (dispatch) => {
    try {
        dispatch({ type: LOADING, payload: true });

        const { data } = await createDescription(fromData);
        dispatch({ type: CREATE_DESC, payload: data });
        handleClose()

        await updateBoardLog({
            task: fromData.cardId,
            project: boardId,
            user: userData._id,
            action: "Description Added"
        });
        dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message);
        }
        dispatch({ type: LOADING, payload: false });
    }
};






