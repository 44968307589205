// import node module libraries
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { Link, useParams } from "react-router-dom";
import {
  Dropdown,
  Card,
  Button,
  Modal,
  Form,
  Accordion,
  ListGroup,
  useAccordionButton,
} from "react-bootstrap";
// import sub custom components
import KanbanTask from "./KanbanTask";
import {
  deleteListAction,
  fetchListByBoardAndFilterAction,
} from "actions/boardList";
import { useDispatch } from "react-redux";
import { createCardAction, importCardAction } from "actions/boardCard";
import {
  createGroupAction,
  editGroupAction,
  hideGroupsAction,
} from "actions/groups";
import { throttle } from "lodash";

const KanbanColumn = React.memo((props) => {
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const { id: idOfBoard } = useParams();
  const dispatch = useDispatch();
  const {
    columnData,
    setListId,
    filterType,
    selectedCard,
    searched,
    setMetaId,
    setShowMetaModal,
  } = props;

  const [showModal, setShowModal] = useState(false);
  const [idOfList, setIdOfList] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [cardId, setCardId] = useState(false);
  const handleShowModal = (id) => {
    setIdOfList(id);
    setShowForm(true);
  };
  const handleDeleteList = (id) => {
    setListToDelete(id);
    setShowDeleteModal(true);
  };
  const confirmDeleteList = () => {
    if (listToDelete && confirmationMessage === "DELETE LIST") {
      dispatch(deleteListAction(listToDelete, idOfBoard));
      setListToDelete(null);
      setShowDeleteModal(false);
      setConfirmationMessage("");
    }
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showImporteModal, setShowImporteModal] = useState(false);
  const [listToDelete, setListToDelete] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [file, setFile] = useState(null);
  //ADD CARD

  const [cardData, setCardData] = useState({
    title: "",
    listId: idOfList,
    boardId: idOfBoard,
    assignedTo: null,
    dueDate: "",
    position: "",
    border: "#4FC1E8",
    createdBy: null,
  });
  // console.log(columnData.cards);
  const handleAddTask = async (e) => {
    dispatch(
      createCardAction(
        {
          ...cardData,
          listId: idOfList,
          boarId: idOfBoard,
          createdBy: userData._id,
        },
        handleClose,
        handleCloseX,
        idOfBoard
      )
    );

    e.preventDefault();
  };

  // import card
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a JSON file");
    } else {
      const formData = new FormData();
      formData.append("file", file);
      dispatch(importCardAction(formData, handleClose, idOfBoard, idOfList));
    }
  };
  const handleClose = () => {
    setCardData({
      title: "",
      listId: idOfList,
      boardId: idOfBoard,
      assignedTo: null,
      dueDate: "",
      position: "",
      border: "#4FC1E8",
      createdBy: null,
    });
    setShowModal(false);
    setShowImporteModal(false);
  };
  const handleCloseX = () => {
    setCardData({
      title: "",
      listId: idOfList,
      boardId: idOfBoard,
      assignedTo: null,
      dueDate: "",
      position: "",
      border: "#4FC1E8",
      createdBy: null,
    });
    setShowForm(false);
  };
  // card group
  const [showGroup, setShowGroup] = useState(false);
  const [groupId, setGroupId] = useState(null);
  const [group, setGroup] = useState({
    title: "",
    listId: "",
  });
  const handleCloseGroupModale = () => {
    setShowGroup(false);
    setGroupId(null);
    setGroup({
      title: "",
      listId: "",
    });
  };
  const handleAddGroup = () => {
    dispatch(createGroupAction(group, handleCloseGroupModale));
  };
  const handleUpdateGroup = () => {
    dispatch(editGroupAction(group, groupId, handleCloseGroupModale));
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      className="btn-icon btn btn-ghost btn-sm rounded-circle"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));

  const ActionMenu = () => {
    return (
      <Dropdown drop="start">
        <Dropdown.Toggle as={CustomToggle}>
          <i className="fe fe-more-horizontal fs-4"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu align="start">
          <Dropdown.Item
            eventKey="1"
            className="d-flex align-items-center"
            onClick={() => setListId(columnData?._id)}
          >
            <i className="dropdown-item-icon fe fe-edit"></i> Edit List
          </Dropdown.Item>

          {userData?._id === columnData?.boardId?.owner ||
          userData?.role === "admin" ||
          userData?.role === "learning-architect" ? (
            <Dropdown.Item
              eventKey="5"
              className="d-flex align-items-center"
              onClick={() => handleDeleteList(columnData?._id)}
            >
              <i className="dropdown-item-icon fe fe-trash-2"></i> Delete List
            </Dropdown.Item>
          ) : null}
          <Dropdown.Item
            eventKey="1"
            className="d-flex align-items-center"
            onClick={() => {
              setGroup({ ...group, listId: columnData?._id });
              setShowGroup(true);
            }}
          >
            <i className="dropdown-item-icon fe fe-plus"></i> Add Group
          </Dropdown.Item>
          {columnData.hide ? (
            <Dropdown.Item
              eventKey="1"
              className="d-flex align-items-center"
              onClick={() => {
                dispatch(fetchListByBoardAndFilterAction(idOfBoard, []));
              }}
            >
              <i className="dropdown-item-icon fe fe-plus"></i> Show Groups
            </Dropdown.Item>
          ) : (
            <Dropdown.Item
              eventKey="1"
              className="d-flex align-items-center"
              onClick={() => {
                dispatch(hideGroupsAction(columnData?._id));
              }}
            >
              <i className="dropdown-item-icon fe fe-plus"></i> Hide Groups
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };
  const GroupMenu = ({ group }) => {
    return (
      <Dropdown drop="start">
        <Dropdown.Toggle as={CustomToggle}>
          <i className="fe fe-more-horizontal fs-4"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu align="start">
          <Dropdown.Item
            eventKey="1"
            className="d-flex align-items-center"
            onClick={() => {
              setGroup({ title: group.title, listId: columnData._id });
              setShowGroup(true);
              setGroupId(group._id);
            }}
          >
            <i className="dropdown-item-icon fe fe-edit"></i> Edit Group
          </Dropdown.Item>

          <Dropdown.Item
            eventKey="5"
            className="d-flex align-items-center"
            // onClick={() => handleDeleteList(columnData?._id)}
          >
            <i className="dropdown-item-icon fe fe-trash-2"></i> Delete Group
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };
  //use Memo
  const [openGroups, setOpenGroups] = useState([]);
  const [size, setSize] = useState(10);
  const [start, setStart] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [hasPrev, setHasPrev] = useState(false);
  const scrollableRef = useRef(null);
  const ContextAwareToggle = ({ children, eventKey, onToggle }) => {
    const handleToggle = () => {
      onToggle(eventKey); // Call the toggle function with the eventKey (group ID)
    };

    return (
      <Fragment>
        <Link
          to="#"
          onClick={handleToggle}
          className="d-flex align-items-center text-inherit text-decoration-none h4 mb-0"
          data-bs-toggle="collapse"
          aria-controls={`collapse-${eventKey}`}
        >
          <div className="me-auto">{children.title}</div>
          <span className="chevron-arrow ms-4">
            <i className="fe fe-chevron-down fs-4"></i>
          </span>
        </Link>
        <GroupMenu group={children} />
      </Fragment>
    );
  };
  const handleToggle = (groupId) => {
    setOpenGroups((prev) =>
      prev.includes(groupId)
        ? prev.filter((id) => id !== groupId) // Remove if already open
        : prev.length > 1
        ? [...prev.filter((id) => id !== prev[0]), groupId]
        : [...prev, groupId]
    );
  };
  const renderedGroups = () => {
    return columnData?.groups?.map((group, index) => (
      <ListGroup.Item
        key={index}
        as="li"
        style={{
          width: "18rem",
          height: "fit-content",
          borderRadius: "5px",
          marginBottom: "20px",
        }}
      >
        <ContextAwareToggle eventKey={group._id} onToggle={handleToggle}>
          {group}
        </ContextAwareToggle>
        {/* Only show the collapse if the group is in the openGroups array */}
        <Accordion.Collapse in={openGroups.includes(group._id)}>
          <Draggable draggableId={group._id} index={index}>
            {(provided) => (
              <div
                className="group-kanban-container"
                {...provided.draggableProps}
                ref={provided.innerRef}
              >
                <div>
                  {/* Group Body - Droppable Area for Cards */}
                  <Droppable droppableId={`${group?._id}`}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        className="tasks-container"
                        style={{
                          width: "95%",
                          margin: "auto",
                          minHeight: "120px",
                        }}
                        {...provided.droppableProps}
                      >
                        {group?.cards
                          ?.slice(
                            0,
                            openGroups.includes(group._id)
                              ? group?.cards.length
                              : 0
                          )
                          .map((task, index) => (
                            <KanbanTask
                              task={task}
                              key={task._id}
                              index={index}
                              cardId={cardId}
                              setCardId={setCardId}
                              showModal={showModal}
                              setShowModal={setShowModal}
                              setMetaId={setMetaId}
                              setShowMetaModal={setShowMetaModal}
                            />
                          ))}
                        {provided.placeholder}{" "}
                        {/* Placeholder to maintain space when dragging */}
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
            )}
          </Draggable>
        </Accordion.Collapse>
      </ListGroup.Item>
    ));
  };
  const filteredTasks = useMemo(() => {
    return columnData?.cards?.filter((task) => {
      const isMetaTask = task?.type === "meta";
      const isOnlyMeTask = task?.type === "onlyMe";
      const isAssignedToUser = task?.assignedTo?._id === userData?._id;
      const isBoardOwner = task?.boardId?.owner === userData?._id;

      const taskConditions =
        isMetaTask && (isAssignedToUser || userData?.role || isBoardOwner);

      const onlyMeConditions =
        isOnlyMeTask &&
        (isAssignedToUser || (task.inviteTeacher === true && isBoardOwner));

      const passesFilterType =
        filterType.includes(task?.type) || filterType.length === 0;
      const isSearchedOwner =
        searched && isBoardOwner
          ? selectedCard.includes(task?.title)
          : !task?.cardParent;

      return (
        ((taskConditions || onlyMeConditions) &&
          passesFilterType &&
          isSearchedOwner) ||
        task?.type === "classic"
      );
    });
  }, [columnData, userData, filterType, searched, selectedCard]);

  const renderedCards = () => {
    setHasPrev(start > 0);

    return filteredTasks
      ?.slice(start, start + size)
      ?.map((task, index) => (
        <KanbanTask
          task={task}
          key={task?._id}
          index={index}
          cardId={cardId}
          setCardId={setCardId}
          showModal={showModal}
          setShowModal={setShowModal}
          setMetaId={setMetaId}
          setShowMetaModal={setShowMetaModal}
        />
      ));
  };

  const loadMoreTasks = useCallback(() => {
    setStart((prevStart) => prevStart + size);
  }, []);

  const loadPrevTasks = useCallback(() => {
    if (hasPrev) {
      setStart((prevStart) => Math.max(prevStart - size, 0));
    }
  }, [hasPrev, size]);

  // Effect to detect scrolling to the top and trigger loading of previous tasks
  const handleScroll = throttle(() => {
    const scrollableElement = scrollableRef.current;

    // Check if there are more tasks to load
    if (start + size >= filteredTasks.length) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }

    // Load previous tasks when scrolled to the top
    if (scrollableElement?.scrollTop < 50 && hasPrev) {
      loadPrevTasks();
    }
    const maxScrollTop =
      scrollableElement.scrollHeight - scrollableElement.clientHeight;

    // Load more tasks when scrolled past a certain point
    if (scrollableElement?.scrollTop > maxScrollTop - 20 && hasMore) {
      loadMoreTasks();
    }
  }, 100);

  // Effect to detect scrolling and load tasks
  useEffect(() => {
    const scrollableElement = scrollableRef.current;

    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleScroll);

      // Cleanup event listener on component unmount
      return () => {
        scrollableElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, [
    hasPrev,
    hasMore,
    start,
    size,
    filteredTasks.length,
    loadPrevTasks,
    loadMoreTasks,
    handleScroll,
  ]);

  return (
    <div ref={scrollableRef} style={{ overflowY: "auto", height: "500px" }}>
      <Fragment>
        <Card
          className="card bg-gray-200 shadow-none rounded-3 d-inline-block me-4 align-top mb-4 task-card"
          style={{
            height:
              showForm === false &&
              (columnData?.cards?.length || columnData?.groups?.length) === 0
                ? "100px"
                : (columnData?.cards?.length || columnData?.groups?.length) ===
                  0
                ? "150px"
                : "auto",
          }}
        >
          <Card.Body className="card-body p-3">
            {/* task list */}
            <div className="task-list">
              {/* content */}
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                  {/* column heading */}
                  <h4 className="mb-0">{columnData?.title}</h4>
                </div>
                <div className="d-flex align-items-center">
                  {/* dropdown */}
                  <ActionMenu />
                </div>
              </div>

              {/* task list */}
              <div className="task-kanban">
                <Droppable droppableId={`${columnData?._id}`}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      className="tasks-container"
                      {...provided.droppableProps}
                    >
                      <Accordion defaultActiveKey={columnData?.groups[0]?._id}>
                        <ListGroup as="ul" variant="flush">
                          {renderedGroups()}
                        </ListGroup>
                      </Accordion>
                      {renderedCards()}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>

              <div
                style={{
                  position: "relative",
                  top:
                    (columnData?.cards?.length ||
                      columnData?.groups?.length) === 0
                      ? "-110px"
                      : "0",
                }}
              >
                {/* button */}
                {showForm ? (
                  <div>
                    <Form onSubmit={(e) => handleAddTask(e)}>
                      <Form.Group className="mb-1">
                        <Form.Control
                          type="text"
                          placeholder="Add Card Title"
                          id="title"
                          value={cardData?.title}
                          required
                          onChange={(e) =>
                            setCardData({
                              ...cardData,
                              title: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    </Form>
                    <Button variant="info" onClick={(e) => handleAddTask(e)}>
                      Add card
                    </Button>
                    <Button
                      variant="success"
                      style={{ marginLeft: "10px" }}
                      onClick={() => setShowImporteModal(true)}
                    >
                      import card
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid transparent",
                        color: "black",
                      }}
                      onClick={handleCloseX}
                    >
                      X
                    </Button>
                  </div>
                ) : (
                  <div className="d-grid " style={{}}>
                    <Button
                      type="button"
                      variant="outline-secondary"
                      size="sm"
                      onClick={() => handleShowModal(columnData._id)}
                    >
                      <i className="fe fe-plus-circle me-1"></i>Add Card
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Card.Body>
        </Card>
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this list?</p>
            <h4 className="text-secondary">
              you must write "DELETE LIST" to confirm the deletion
            </h4>
            <input
              className="form-control"
              rows="3"
              placeholder="Enter confirmation message"
              value={confirmationMessage}
              onChange={(e) => setConfirmationMessage(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={confirmDeleteList}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showImporteModal}
          onHide={() => setShowImporteModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Import Card</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Select Card To Import </p>
            <div>
              <input
                className="form-control"
                type="file"
                accept=".json"
                onChange={handleFileChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowImporteModal(false)}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={() => handleUpload()}>
              Import
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showGroup} onHide={() => handleCloseGroupModale()}>
          <Modal.Header closeButton>
            <Modal.Title> {groupId ? "Update Group" : "Add Group"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <input
                className="form-control"
                type="text"
                onChange={(e) => setGroup({ ...group, title: e.target.value })}
                placeholder="Title"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => handleCloseGroupModale()}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                if (groupId) {
                  handleUpdateGroup();
                } else {
                  handleAddGroup();
                }
              }}
            >
              {groupId ? "Update Group" : "Save"}
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    </div>
  );
});

export default KanbanColumn;
