import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AUTH,
  GET_STUDENTS,
  LOADING,
  LOGOUT,
  UPDATE_PLACMENT_TEST,
} from "./constants";
import { getStudents, signInClient, signUpClient, updateTest } from "api";

export const signUpClientAction = (formData, navigate) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    //signup the user
    const { data } = await signUpClient(formData);
    // console.log(data?.data?.user);
    const userDataWithRole = { ...data.result, role: "student" };

    localStorage.setItem("userData", JSON.stringify(userDataWithRole));
    localStorage.setItem("userToken", data.token);
    localStorage.setItem("userId", data.result._id);
    dispatch({ type: LOADING, payload: false });
    toast.success(data.message, {
      autoClose: 1000,
    });

    const userToken = data.token;

    if (userToken) {
      // User is authenticated
      dispatch({ type: AUTH, payload: { is_authenticated: true } });
      // navigate('/')
      navigate("/kanban_board_by_member");
    }

    //  window.location.href = "/authentication/mail-confirmation";
    // navigate("/authentication/sign-in/student");
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
  }
};
export const signInClientAction = (formData, navigate) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    //login the user
    const { data } = await signInClient(formData);
    const userDataWithRole = { ...data.result, role: "student" };

    localStorage.setItem("userData", JSON.stringify(userDataWithRole));
    localStorage.setItem("userToken", data.token);
    localStorage.setItem("userId", data.result._id);
    dispatch({ type: LOADING, payload: false });
    toast.success(data.message, {
      autoClose: 1000,
    });

    const userToken = data.token;

    if (userToken) {
      // User is authenticated
      dispatch({ type: AUTH, payload: { is_authenticated: true } });
      // navigate('/')
      window.location.href = "/kanban_board_by_member?PlacmentTest=1";
    } else {
      // User is not authenticated
      dispatch({ type: AUTH, payload: { is_authenticated: false } });
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
      console.log(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};
// export const authClientAction = () => async (dispatch) => {
//   try {
//     dispatch({ type: LOADING, payload: true });

//     if (data?.data?.is_authenticated) {
//       dispatch({ type: AUTH, payload: data?.data });
//     }

//     dispatch({ type: LOADING, payload: false });
//   } catch (error) {
//     dispatch({ type: LOADING, payload: false });
//     if (error?.response?.data?.message) {
//       console.log(error.response.data.message);

//     }
//   }
// };

export const authClientAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const userToken = localStorage.getItem("userToken");
    if (userToken) {
      // User is authenticated
      const userData = JSON.parse(userToken);
      dispatch({ type: AUTH, payload: { is_authenticated: true } });
    } else {
      // User is not authenticated
      dispatch({ type: AUTH, payload: { is_authenticated: false } });
    }

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};

export const logOutClientAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    // Perform the logout Clientaction, for example, calling a logout API or clearing user data.

    // Remove user data and token from local storage
    localStorage.removeItem("userData");
    localStorage.removeItem("userToken");
    window.location.href = "/authentication/sign-in/student";

    dispatch({ type: LOGOUT });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};

export const fetchStudentsAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await getStudents();

    dispatch({ type: GET_STUDENTS, payload: data.clients });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};

export const updateTestAction = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await updateTest(id, formData);

    const userDataString = localStorage.getItem("userData");
    const userData = JSON.parse(userDataString);

    localStorage.setItem(
      "userData",
      JSON.stringify({ ...userData, placmentTest: data.data })
    );
  } catch (error) {
    console.log(error);
  }
};
export const updateTestSpeakingAction =
  (setEditTest, setEdit, id, formData) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const { data } = await updateTest(id, formData);
      dispatch({ type: UPDATE_PLACMENT_TEST, payload: data.data });
      setEdit(false);
      setEditTest(false);
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      dispatch({ type: LOADING, payload: false });
      console.log(error);
    }
  };
